import { ClientId } from '../clients/client-id';
import { Price, ServiceId } from '../services';
import { UserId } from '../users';
import { WorkerId } from '../workers';
import { WaitingListId } from './waiting-list-id';
import { DateString, JSONable, ProfileImage, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type WaitingListDetails = {
  readonly _id: WaitingListId;
  readonly forWorker: {
    readonly _id: WorkerId;
    readonly user: {
      readonly _id: UserId;
      readonly firstname: string;
      readonly lastname: string;
    };
    readonly profilePhoto: ProfileImage;
  };
  readonly user: {
    readonly _id: UserId;
    readonly clientId: ClientId | undefined;
    readonly phone: string;
    readonly firstname: string;
    readonly lastname: string;
    readonly profilePhoto: ProfileImage | undefined;
    readonly facebookId: string | undefined;
  };
  readonly date: DateString;
  readonly services:
    | {
        readonly _id: ServiceId;
        readonly name: string;
        readonly price: Price;
      }[]
    | undefined;
  readonly boostStatus:
    | {
        readonly isBoost: true;
        readonly boostCost: number;
        readonly boostProfit: number | undefined;
        readonly hideBoostDetails: boolean;
        readonly canClaim: boolean;
        readonly isClaimPending: boolean;
        readonly canAcceptCommission: boolean;
      }
    | {
        readonly isBoost: false;
      };
};

const JSON: t.Type<WaitingListDetails, JSONable> = t.type(
  {
    _id: WaitingListId,
    forWorker: t.type(
      {
        _id: WorkerId.JSON,
        user: t.type(
          {
            _id: UserId,
            firstname: t.string,
            lastname: t.string,
          },
          'WorkerUser',
        ),
        profilePhoto: ProfileImage,
      },
      'ForWorker',
    ),
    user: t.type(
      {
        _id: UserId,
        clientId: optionull(ClientId),
        phone: t.string,
        firstname: t.string,
        lastname: t.string,
        profilePhoto: optionull(ProfileImage),
        facebookId: optionull(t.string),
      },
      'User',
    ),
    date: DateString.JSON,
    services: optionull(
      t.array(
        t.type(
          {
            _id: ServiceId,
            name: t.string,
            price: Price,
          },
          'Service',
        ),
        'Services',
      ),
    ),
    boostStatus: t.union(
      [
        t.type({
          isBoost: t.literal(true),
          boostCost: t.number,
          boostProfit: optionull(t.number),
          hideBoostDetails: t.boolean,
          canClaim: t.boolean,
          isClaimPending: t.boolean,
          canAcceptCommission: t.boolean,
        }),
        t.type({
          isBoost: t.literal(false),
        }),
      ],
      'BoostStatus',
    ),
  },
  'AvailableDayDetails',
);

export const WaitingListDetails = {
  JSON,
};
