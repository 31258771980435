import { WorkerId } from '../../../workers/workerId';
import { DateWorkingSchedule } from '../dateWorkingSchedule';
import { EditWorkingSchedule } from '../editWorkingSchedule';
import { ProWorkingScheduleApi } from '../proWorkingScheduleApi';
import { WorkerSchedule } from '../workerSchedule';
import { WorkerScheduleId } from '../workingScheduleId';
import { DateString, HttpClient, Option, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as Nea from 'fp-ts/lib/NonEmptyArray';
import * as t from 'io-ts';

export const proWorkingScheduleHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): ProWorkingScheduleApi => {
  const { apiBaseUrl, http } = env;

  const editWorkerScheduleDecoder = http.decode.response(UnknownApiError, t.unknown);
  const checkWorkingScheduleOverlapsDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      scheduleOverlaps: t.boolean,
      specificDateSchedules: t.array(WorkerSchedule.SpecificDate.JSON),
    }),
  );
  const getWorkerScheduleDecoder = http.decode.response(UnknownApiError, t.array(DateWorkingSchedule.JSON));
  const getWorkersSchedulesDecoder = http.decode.response(
    UnknownApiError,
    t.array(
      t.type({
        workerId: WorkerId.JSON,
        days: t.array(DateWorkingSchedule.JSON),
      }),
    ),
  );
  const findWorkingScheduleByDateDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      schedule: Option.json(WorkerSchedule.Any.JSON),
    }),
  );
  const deleteWorkerSchedulesByIdDecoder = http.decode.response(UnknownApiError, t.unknown);

  return {
    editWorkingSchedule: async ({
      pageId,
      workerIds,
      workerScheduleId,
      workingSchedule,
      overrideSpecificDaySchedules,
      startDateSetByUser,
    }) => {
      const requestBody: {
        readonly workerIds: Nea.NonEmptyArray<WorkerId>;
        readonly workerScheduleId: Option<WorkerScheduleId>;
        readonly workingSchedule: EditWorkingSchedule.Any;
        readonly overrideSpecificDaySchedules: boolean;
        readonly startDateSetByUser: boolean;
      } = {
        workerIds: workerIds,
        workerScheduleId: workerScheduleId,
        workingSchedule: workingSchedule,
        overrideSpecificDaySchedules: overrideSpecificDaySchedules,
        startDateSetByUser: startDateSetByUser,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/working-schedules`,
            data: requestBody,
          },
          editWorkerScheduleDecoder,
        ),
      );
    },
    checkWorkingScheduleOverlaps: async ({ pageId, workerIds, workingSchedule }) => {
      const requestBody: {
        readonly workerIds: Nea.NonEmptyArray<WorkerId>;
        readonly workingSchedule: EditWorkingSchedule.Any;
      } = {
        workerIds: workerIds,
        workingSchedule: workingSchedule,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/working-schedule-overlaps`,
            data: requestBody,
          },
          checkWorkingScheduleOverlapsDecoder,
        ),
      );
    },
    getWorkerSchedule: async ({ pageId, workerId, interval }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/workers/${encodeURIComponent(
              workerId,
            )}/working-schedules`,
            params: {
              from: interval.from,
              to: interval.to,
            },
          },
          getWorkerScheduleDecoder,
        ),
      );
    },
    getWorkersSchedules: async ({ pageId, interval, workerIds }) => {
      const queryParams: {
        readonly from: DateString;
        readonly to: DateString;
        readonly workerIds: Option<string[]>;
      } = {
        from: interval.from,
        to: interval.to,
        workerIds: Option.json(t.array(WorkerId.JSON)).encode(workerIds),
      };

      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/working-schedules`,
            params: queryParams,
          },
          getWorkersSchedulesDecoder,
        ),
      );
    },
    findWorkingScheduleByDate: async ({ pageId, workerId, date }) => {
      const queryParams: {
        date: DateString;
      } = {
        date: date,
      };

      const { schedule } = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/workers/${encodeURIComponent(
              workerId,
            )}/worker-schedules`,
            params: queryParams,
          },
          findWorkingScheduleByDateDecoder,
        ),
      );

      return schedule;
    },
    deleteWorkerScheduleById: async ({ pageId, workerId, workerScheduleId, interval, deleteSpecificDaySchedules }) => {
      const queryParams: {
        from: DateString;
        to: Option<DateString>;
        deleteSpecificDaySchedules: boolean;
      } = {
        from: interval.from,
        to: interval.to,
        deleteSpecificDaySchedules: deleteSpecificDaySchedules,
      };
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/workers/${encodeURIComponent(
              workerId,
            )}/worker-schedules/${encodeURIComponent(workerScheduleId)}`,
            params: queryParams,
          },
          deleteWorkerSchedulesByIdDecoder,
        ),
      );
    },
  };
};
