import { InventoryId } from '../../pro/inventories/inventoryId';
import { ProductAvailability } from '../../pro/products/product/productAvailability';
import { CheckoutItemTotal } from '../checkoutItemTotal';
import { CheckoutItemTotalScaledNumber } from '../checkoutItemTotalScaledNumber';
import { CheckoutTotals } from '../checkoutTotals';
import { CheckoutTransactionPayment } from '../checkoutTransactionPayment';
import { CheckoutTransactionProductDetails } from '../checkoutTransactionProductDetails';
import { CheckoutUserPreview } from '../checkoutUserPreview';
import { CompanyVatStatus } from '../companies';
import { MeroUnits, Positive, ScaledNumber } from '@mero/shared-sdk';

export type Product<Unit extends MeroUnits.Any> = {
  readonly type: 'Product';
  readonly transactionItemId: string;
  readonly product: CheckoutTransactionProductDetails<Unit>;
  readonly total: CheckoutItemTotal<ScaledNumber, Unit>;
  readonly quantity: Positive<ScaledNumber>;
  readonly availability: ProductAvailability.Any;
  readonly saleOwner: CheckoutUserPreview;
  readonly inventoryId: InventoryId;
};

const getTotals = <Unit extends MeroUnits.Any>(
  item: Product<Unit>,
  unit: Unit,
  payments: CheckoutTransactionPayment.Any<ScaledNumber, Unit>[],
  companyVat: CompanyVatStatus.Any<ScaledNumber>,
  decimals: number,
  includeItemsPaidWithMembership = false,
): CheckoutTotals<ScaledNumber, Unit> => {
  includeItemsPaidWithMembership;
  payments;
  unit;

  return CheckoutItemTotalScaledNumber.getTotals(item.total, companyVat, decimals);
};

/**
 * Check if given product item paid with a membership
 */
const isPaidWithMembership = <Unit extends MeroUnits.Any>(
  item: Product<Unit>,
  unit: Unit,
  payments: CheckoutTransactionPayment.Any<ScaledNumber, Unit>[],
  companyVat: CompanyVatStatus.Any<ScaledNumber>,
  decimals: number,
):
  | {
      readonly isPaidWithMembership: true;
      readonly totals: CheckoutTotals<ScaledNumber, Unit>;
    }
  | {
      readonly isPaidWithMembership: false;
    } => {
  item;
  decimals;
  companyVat;
  payments;
  unit;
  return { isPaidWithMembership: false };
};

export const Product = {
  getTotals,
  isPaidWithMembership,
};
