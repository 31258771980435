import { PagePermissionOverview } from './pagePermissionOverview';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type PagePermissionsGroupOverview = {
  readonly title: string;
  readonly permissions: PagePermissionOverview[];
};

const JSON: t.Type<PagePermissionsGroupOverview, JSONable> = t.type(
  {
    title: t.string,
    permissions: t.array(PagePermissionOverview.JSON),
  },
  'PagePermissionsGroupOverview',
);

export const PagePermissionsGroupOverview = {
  JSON,
};
