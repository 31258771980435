import { err, ok, Result } from '../common/result';
import { BasicCalculator } from './basicCalculator';
import { ExtendedCalculator } from './extendedCalculator';
import { HasFromNumber } from './hasFromNumber';
import { HasToNumber } from './hasToNumber';
import { Negative, NegativeModule } from './negative';
import { NonNegative, NonNegativeModule } from './nonNegative';
import { NonPositive, NonPositiveModule } from './nonPositive';
import { NonZero, NonZeroModule } from './nonZero';
import { Positive, PositiveModule } from './positive';
import { PositiveInt } from './positiveInt';
import { Zero, ZeroModule } from './zero';
import * as E from 'fp-ts/lib/Either';
import * as t from 'io-ts';

export type ScaledNumber = {
  /**
   * Scaled numeric value (integer), ex: 100 for 1.00 when {@link scale} is 2 (value / 10^scale)
   * TODO: use int type
   */
  readonly value: number;
  /**
   * Scale of the {@link value}, ex: 2 for 1.00
   * @default 0
   */
  readonly scale?: number;
};

const MINUS_ONE: Negative<ScaledNumber> = {
  value: -1,
} as Negative<ScaledNumber>;

const ZERO: Zero<ScaledNumber> = {
  value: 0,
  scale: 0,
} as Zero<ScaledNumber>;

const ONE: Positive<ScaledNumber> = {
  value: 1,
} as Positive<ScaledNumber>;

const zero = (): ScaledNumber => ZERO;

const add = (a: ScaledNumber, b: ScaledNumber): ScaledNumber => {
  const aScale: number = a.scale ?? 0;
  const bScale: number = b.scale ?? 0;
  const scale: number = Math.max(aScale, bScale);

  const scaledA = aScale === scale ? a.value : a.value * Math.pow(10, scale - aScale);
  const scaledB = bScale === scale ? b.value : b.value * Math.pow(10, scale - bScale);

  return {
    value: scaledA + scaledB,
    scale,
  };
};

const sub = (a: ScaledNumber, b: ScaledNumber): ScaledNumber => {
  const aScale: number = a.scale ?? 0;
  const bScale: number = b.scale ?? 0;
  const scale: number = Math.max(aScale, bScale);

  const scaledA = aScale === scale ? a.value : a.value * Math.pow(10, scale - aScale);
  const scaledB = bScale === scale ? b.value : b.value * Math.pow(10, scale - bScale);

  return {
    value: scaledA - scaledB,
    scale,
  };
};

const mul = (a: ScaledNumber, b: ScaledNumber): ScaledNumber => {
  const aScale: number = a.scale ?? 0;
  const bScale: number = b.scale ?? 0;
  const scale: number = aScale + bScale;

  return {
    value: Math.round(a.value * b.value),
    scale,
  };
};

/**
 * Divide value {@link a} by {@link b} rounding value to {@link decimals}
 */
const div = (a: ScaledNumber, b: ScaledNumber, decimals: number): ScaledNumber => {
  return {
    value: Math.round((toNumber(a) / toNumber(b)) * Math.pow(10, decimals)),
    scale: decimals,
  };
};

/**
 * Round {@link ScaledNumber} to a given scale.
 * If new {@link decimals} is greater than {@link a.scale}, the value will be multiplied to match new scale
 * If new {@link decimals} is lower than {@link a.scale}, the value will be divided and rounded to match new scale
 */
const round = (a: ScaledNumber, decimals: number): ScaledNumber => {
  const aScale: number = a.scale ?? 0;
  if (aScale === decimals) {
    return a;
  } else if (aScale > decimals) {
    return {
      value: Math.round(a.value / Math.pow(10, aScale - decimals)),
      scale: decimals,
    };
  } else {
    return {
      value: a.value * Math.pow(10, decimals - aScale),
      scale: decimals,
    };
  }
};

/**
 * Compare two {@link ScaledNumber} values
 * @returns -1 if a < b, 0 if a === b, 1 if a > b
 */
const compare = (a: ScaledNumber, b: ScaledNumber): -1 | 0 | 1 => {
  const scale = Math.max(a.scale ?? 0, b.scale ?? 0);
  const scaledA = round(a, scale);
  const scaledB = round(b, scale);

  if (scaledA.value < scaledB.value) {
    return -1;
  } else if (scaledA.value > scaledB.value) {
    return 1;
  } else {
    return 0;
  }
};

/**
 * Find min value from given list
 * @param a at least one value is required
 * @param rest optional other values to compare
 * @returns minimal {@link ScaledNumber} value
 */
const min = (a: ScaledNumber, ...rest: ScaledNumber[]): ScaledNumber => {
  let min = a;

  for (const n of rest) {
    const cmp = compare(n, min);

    min = cmp < 0 ? n : min;
  }

  return min;
};

/**
 * Find max value from given list
 * @param a at least one value is required
 * @param rest optional other values to compare
 * @returns maximal {@link ScaledNumber} value
 */
const max = (a: ScaledNumber, ...rest: ScaledNumber[]): ScaledNumber => {
  let max = a;

  for (const n of rest) {
    const cmp = compare(n, max);

    max = cmp > 0 ? n : max;
  }

  return max;
};

/**
 * Create {@link ScaledNumber} from a number value and a scale to give number of decimals
 * The {@link value} will be truncated at given {@link decimals}
 * @returns {@link ScaledNumber} with {@link scale} = {@link decimals}
 */
const fromNumber = (value: number, decimals: number): ScaledNumber => ({
  value: Math.round(value * Math.pow(10, decimals)),
  scale: decimals,
});

/**
 * Convert {@link ScaledNumber} to a number value
 */
const toNumber = (value: ScaledNumber): number => value.value / Math.pow(10, value.scale ?? 0);

/**
 * Create {@link ScaledNumber} from an integer value
 * if {@link value} is not an integer, it will be truncated
 * @returns {@link ScaledNumber} with {@link scale} = 0
 */
const fromInteger = (value: number): ScaledNumber => fromNumber(value, 0);

const toStr = (value: ScaledNumber): string => {
  if (value.scale === 0 || value.scale === undefined) {
    return value.value.toString();
  } else {
    const div = Math.pow(10, value.scale);
    const absValue = Math.abs(value.value);
    const sign = value.value < 0 ? '-' : '';
    const intStr = Math.trunc(absValue / div).toString();
    const decimalStr = (absValue % div).toString();
    const padding = '0'.repeat(Math.max(0, value.scale - decimalStr.length));

    return `${sign}${intStr}.${padding}${decimalStr}`;
  }
};

/**
 * Parse a {@link ScaledNumber} from a string
 * optimistic version, assumes string is only digits and dot as decimals separator, ex: 1234.992
 */
const fromStr = (str: string): E.Either<Error, ScaledNumber> => {
  const firstDotIndex = str.indexOf('.');

  if (firstDotIndex === -1) {
    const value = Number.parseInt(str);
    if (Number.isNaN(value)) {
      return E.left(new Error(`Failed to parse ScaledNumber from string "${str}"`));
    }

    return E.right({
      value: value,
      scale: 0,
    });
  } else {
    const decimalSlice = str.slice(firstDotIndex + 1);
    const secondDotIndex = decimalSlice.indexOf('.');

    if (secondDotIndex !== -1) {
      return E.left(new Error(`Failed to parse ScaledNumber from string "${str}": extra dot found in decimals`));
    }

    const intSlice = str.slice(0, firstDotIndex);
    const value = Number.parseInt(intSlice + decimalSlice);

    if (Number.isNaN(value)) {
      return E.left(new Error(`Failed to parse ScaledNumber from string "${str}"`));
    }

    return E.right({
      value: value,
      scale: str.length - firstDotIndex - 1,
    });
  }
};

const ScaledNumberBasicCalculator: BasicCalculator<ScaledNumber> = {
  zero,
  add,
  sub,
  mul,
  div,
  round,
  compare,
};

const ScaledNumberExtendedCalculator: ExtendedCalculator<ScaledNumber> =
  ExtendedCalculator.build(ScaledNumberBasicCalculator);

const JSON: t.Type<ScaledNumber, ScaledNumber, unknown> = t.exact(
  t.intersection(
    [
      t.type(
        {
          value: t.number,
        },
        '!',
      ),
      t.partial(
        {
          scale: t.number,
        },
        '?',
      ),
    ],
    'ScaledNumber',
  ),
  'Exact<ScaledNumber>',
);

const NonNegativeT = NonNegative.build(ScaledNumberExtendedCalculator);
const NonNegativeM = {
  ...NonNegativeT,
  JSON: NonNegativeT.json(JSON),
};

const NonPositiveT = NonPositive.build(ScaledNumberExtendedCalculator);
const NonPositiveM = {
  ...NonPositiveT,
  JSON: NonPositiveT.json(JSON),
};

const NonZeroT = NonZero.build(ScaledNumberExtendedCalculator);
const NonZeroM = {
  ...NonZeroT,
  JSON: NonZeroT.json(JSON),
};

const PositiveT = Positive.build(ScaledNumberExtendedCalculator);
const PositiveM = {
  ...PositiveT,
  JSON: PositiveT.json(JSON),
  add: (a: Positive<ScaledNumber>, b: Positive<ScaledNumber>): Positive<ScaledNumber> => {
    return PositiveT.unsafeFrom(ScaledNumber.add(a, b));
  },
  mul: (a: Positive<ScaledNumber>, b: Positive<ScaledNumber>): Positive<ScaledNumber> => {
    return PositiveT.unsafeFrom(ScaledNumber.mul(a, b));
  },
  fromPositiveInt: (a: PositiveInt): Positive<ScaledNumber> => {
    return PositiveT.unsafeFrom(ScaledNumber.fromInteger(a));
  },
};

const NegativeT = Negative.build(ScaledNumberExtendedCalculator);
const NegativeM = {
  ...NegativeT,
  JSON: NegativeT.json(JSON),
};

const ZeroT = Zero.build(ScaledNumberExtendedCalculator);
const ZeroM = {
  ...ZeroT,
  JSON: ZeroT.json(JSON),
};

type CastFn<From, To> = (value: From) => Result<To, Error>;

const asNonNegative = (value: ScaledNumber): Result<NonNegative<ScaledNumber>, Error> => {
  if (NonNegativeT.is(value)) {
    return ok(value);
  }

  return err(new Error('Failed to cast ScaledNumber to NonNegative<ScaledNumber>'));
};

const asNonPositive = (value: ScaledNumber): Result<NonPositive<ScaledNumber>, Error> => {
  if (NonPositiveT.is(value)) {
    return ok(value);
  }

  return err(new Error('Failed to cast ScaledNumber to NonPositive<ScaledNumber>'));
};

const asNonZero = (value: ScaledNumber): Result<NonZero<ScaledNumber>, Error> => {
  if (NonZeroT.is(value)) {
    return ok(value);
  }

  return err(new Error('Failed to cast ScaledNumber to NonZero<ScaledNumber>'));
};

const asPositive = (value: ScaledNumber): Result<Positive<ScaledNumber>, Error> => {
  if (PositiveT.is(value)) {
    return ok(value);
  }

  return err(new Error('Failed to cast ScaledNumber to Positive<ScaledNumber>'));
};

const asNegative = (value: ScaledNumber): Result<Negative<ScaledNumber>, Error> => {
  if (NegativeT.is(value)) {
    return ok(value);
  }

  return err(new Error('Failed to cast ScaledNumber to Negative<ScaledNumber>'));
};

const asZero = (value: ScaledNumber): Result<Zero<ScaledNumber>, Error> => {
  if (ZeroT.is(value)) {
    return ok(value);
  }

  return err(new Error('Failed to cast ScaledNumber to Zero<ScaledNumber>'));
};

type ScaledNumberModule = ExtendedCalculator<ScaledNumber> &
  HasFromNumber<ScaledNumber> &
  HasToNumber<ScaledNumber> & {
    /**
     * Find min value from given list
     * @param a at least one value is required
     * @param rest optional other values to compare
     * @returns minimal {@link ScaledNumber} value
     */
    readonly min: (a: ScaledNumber, ...rest: ScaledNumber[]) => ScaledNumber;
    /**
     * Find max value from given list
     * @param a at least one value is required
     * @param rest optional other values to compare
     * @returns maximal {@link ScaledNumber} value
     */
    readonly max: (a: ScaledNumber, ...rest: ScaledNumber[]) => ScaledNumber;
    /**
     * Create {@link ScaledNumber} from an integer value
     * if {@link value} is not an integer, it will be rounded
     * @returns {@link ScaledNumber} with {@link scale} = 0
     */
    readonly fromInteger: (value: number) => ScaledNumber;
    /**
     * Convert a {@link ScaledNumber} value to a string representation
     * WARNING: this is not a UI formatting function, is used for serialization and is dual for {@link fromStr}
     */
    readonly toStr: (value: ScaledNumber) => string;
    /**
     * Try to parse a {@link ScaledNumber} from a string representation (see {@link fromStr})
     */
    readonly fromStr: (str: string) => E.Either<Error, ScaledNumber>;
    /**
     * JSON codec for {@link ScaledNumber}
     */
    readonly JSON: t.Type<ScaledNumber, ScaledNumber, unknown>;
    /**
     * -1
     */
    readonly MINUS_ONE: Negative<ScaledNumber>;
    /**
     * 0
     */
    readonly ZERO: Zero<ScaledNumber>;
    /**
     * 1
     */
    readonly ONE: Positive<ScaledNumber>;

    readonly asNonNegative: CastFn<ScaledNumber, NonNegative<ScaledNumber>>;

    readonly asNonPositive: CastFn<ScaledNumber, NonPositive<ScaledNumber>>;

    readonly asNonZero: CastFn<ScaledNumber, NonZero<ScaledNumber>>;

    readonly asPositive: CastFn<ScaledNumber, Positive<ScaledNumber>>;

    readonly asNegative: CastFn<ScaledNumber, Negative<ScaledNumber>>;

    readonly asZero: CastFn<ScaledNumber, Zero<ScaledNumber>>;

    readonly NonNegative: NonNegativeModule<ScaledNumber> & {
      readonly JSON: t.Type<NonNegative<ScaledNumber>, t.OutputOf<typeof JSON>, t.InputOf<typeof JSON>>;
    };

    readonly NonPositive: NonPositiveModule<ScaledNumber> & {
      readonly JSON: t.Type<NonPositive<ScaledNumber>, t.OutputOf<typeof JSON>, t.InputOf<typeof JSON>>;
    };

    readonly NonZero: NonZeroModule<ScaledNumber> & {
      readonly JSON: t.Type<NonZero<ScaledNumber>, t.OutputOf<typeof JSON>, t.InputOf<typeof JSON>>;
    };

    readonly Positive: PositiveModule<ScaledNumber> & {
      readonly JSON: t.Type<Positive<ScaledNumber>, t.OutputOf<typeof JSON>, t.InputOf<typeof JSON>>;
      readonly add: (a: Positive<ScaledNumber>, b: Positive<ScaledNumber>) => Positive<ScaledNumber>;
      readonly mul: (a: Positive<ScaledNumber>, b: Positive<ScaledNumber>) => Positive<ScaledNumber>;
      readonly fromPositiveInt: (a: PositiveInt) => Positive<ScaledNumber>;
    };

    readonly Negative: NegativeModule<ScaledNumber> & {
      readonly JSON: t.Type<Negative<ScaledNumber>, t.OutputOf<typeof JSON>, t.InputOf<typeof JSON>>;
    };

    readonly Zero: ZeroModule<ScaledNumber> & {
      readonly JSON: t.Type<Zero<ScaledNumber>, t.OutputOf<typeof JSON>, t.InputOf<typeof JSON>>;
    };
  };

export const ScaledNumber: ScaledNumberModule = {
  ...ScaledNumberExtendedCalculator,
  min,
  max,
  fromInteger,
  fromNumber,
  toNumber,
  toStr,
  fromStr,
  JSON,
  MINUS_ONE,
  ZERO,
  ONE,
  NonNegative: NonNegativeM,
  NonPositive: NonPositiveM,
  NonZero: NonZeroM,
  Positive: PositiveM,
  Negative: NegativeM,
  Zero: ZeroM,
  asNonNegative,
  asNonPositive,
  asNonZero,
  asPositive,
  asNegative,
  asZero,
};
