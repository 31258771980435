import { BUSINESS_NAMESPACE } from '../namespaces';
import { IErrorTable } from 'exceptional.js';

export const TABLE: IErrorTable = {
  namespace: BUSINESS_NAMESPACE,
  locale: 'ro',
  errors: {
    0: 'S-a intamplat ceva neprevazut',
    1: '${message}',
    2: 'Nu am putut crea pagina',
    3: 'Aceasta invitatie nu mai este valabila.',
    4: 'O invitatie pentru acest profesionist a fost deja trimisă și așteaptă aprobare.',
    5: 'Nu am gasit invitatia.',
    6: 'Aceasta pagina nu exista.',
    7: 'Nu am putut gasi acest profesionist.',
    8: 'Nu ai dreptul sa faci aceasta operatiune.',
    9: 'Profesionistul cu acest număr de telefon este deja parte din Echipă.',
    10: 'Ai trimis deja o invitatie. Te rugam sa astepti confirmarea.',
    11: 'Nu te-am putut inregistra "In asteptare".',
    12: 'Nu am putut modifica setarile.',
    13: 'Nu am putut modifica orarul.',
    14: 'Nu am putut modifica serviciile.',
    15: 'Profesionistul nu apartine paginii pe care incerci sa o modifici.',
    16: 'Nu am gasit nicio pagina la aceasta adresa ${slug}.',
    17: 'Nu am gasit inregistrarea pe care incerci sa o stergi.',
    18: 'Nu am putut actualiza pagina.',
    19: 'Un singur serviciu poate fi setat ca principal.',
    20: 'Trebuie sa adaugi cel putin un serviciu',
    21: 'Trebuie sa ai un serviciu principal.',
    22: 'Serviciul trebuie sa aiba un nume.',
    23: 'Trebuie sa setezi durata serviciului.',
    24: 'Preţul nu este configurat cum trebuie.',
    25: 'Nu poti şterge acest profesionist deoarece trebuie sa mai fie cel puţin unul în pagină.',
    26: 'Nu am putut adauga rolul.',
    27: 'Acest cont de utilizator este deja inregistrat ca profesionist.',
    28: 'Nu poti publica pagina inainte de a seta locatia exacta.',
    29: 'Acest profesionist nu exista in aceasta pagina.',
    30: 'Nu am putut seta ordinea noua pentru profesionisti.',
    31: 'Nu am putut seta ordinea noua pentru servicii.',
    32: 'Acest serviciu nu exista in aceasta pagina.',
    33: 'Listarea acestei pagini a fost deja solicitată',
    34: 'Delistarea acestei pagini a fost deja solicitată.',
    35: 'Nu am putut crea cardul cadou.',
    36: '${pageName} nu ofera carduri cadou.',
    37: 'Nu am gasit niciun card cadou cu acest id ${id}',
    38: 'Pentru a activa platile online trebuie mai intai sa iti conectezi contul de Stripe.',
    39: 'Codul este expirat sau greșit',
    40: 'Pentru a activa cardurile cadou proprii trebuie mai intai sa iti conectezi contul de Stripe.',
    41: 'Nu am putut crea codul de reducere.',
    42: 'Exista deja o reducere cu acest cod ${code}.',

    // clients
    43: 'Nu am gasit niciun client cu acest id ${id}',
    44: 'Exista deja un client cu acest id ${userId}',
    45: 'Nu am putut crea cererea de programare.',
    46: 'Nu am putut crea cererea de înscriere la profil privat.',
    47: 'Poți alege tipul de acces până la rolul de manager, inclusiv.',
    48: 'Tipul de acces pentru profilul tău poate fi modificat doar de administratorul contului',
    49: 'Nu poti invita un profesionist cu rolul selectat',

    //images
    50: 'Nu am putut seta ordinea noua pentru imagini.',
    51: 'Exista deja un client BOOST inregistrat cu acest id ${userId}',
    52: 'Nu se poate inregistra un client BOOST pentru o programare inactiva (statusul este ${status})',
    53: 'Cererea de revendicare pentru acest client BOOST a fost deja aprobată',
    54: 'Nu am putut gasi un utilizator cu acest id ${userId}',

    55: 'Aceasta adresa (${slug}) nu este disponibila momentan.',

    //appointments
    56: 'Nu poți lăsa o recenzie pentru această programare',
    57: 'Nu poți actualiza recenzia pentru această programare',
    58: 'Nu poți șterge profilul de profesionist pentru ca este invitat in alte pagini',

    81: 'O alta companie cu acelasi cod fiscal este deja inregistrata',
    82: 'Una din programarile de pe aceasta incasare a fost incasata anterior',
    83: 'Casa de marcat selectat nu a fost gasita sau nu este valida',
    84: 'Nu poti printa un bon pentru incasarea ${checkoutTransactionCode}: Incasarea nu e fost finalizata',
    85: 'Nu poti printa un bon pentru incasarea ${checkoutTransactionCode}: Nu a fost selectata o companie',
    86: 'Nu poti printa un bon pentru incasarea ${checkoutTransactionCode}: Emiterea bonului nu a fost selectata',
    87: 'Nu poti printa un bon pentru incasarea ${checkoutTransactionCode}: Un alt bon a fost deja emis',
    88: 'Nu poti descarca bonul ${receiptId}: bonul nu a fost gasit',
    89: 'Nu poti descarca bonul ${receiptId}: bonul nu este in asteptare',

    //cash registry
    90: 'Nu se poate initializa registrul de casa: exista deja unul inregistrat ${cashRegistryId} pentru aceasta companie ${checkoutCompanyId}',
    91: 'Nu se poate modifica bilantului initial: exista deja o tranzactie la data de ${earliestTransactionDate}',
    92: 'Aceasta operațiune produce un sold negativ. Verifică registrul sau modifică tipul operațiunii.',
    93: 'Nu a fost gasit registrul de casa ${cashRegistryId}',
    94: 'Nu a fost gasit registrul de casa al companiei ${checkoutCompanyId}',
    95: 'Aceasta operațiune produce un sold negativ. Verifică registrul sau modifică tipul operațiunii.',
    96: 'Nu se poate adauga aceasta tranzactie: moneda tranzactiei ${transactionAmountUnit} difera fata de bilantul initial ${initialBalanceUnit}',
    97: 'Nu se poate face aceasta modificare tranzactiei ${cashRegistryTransactionId}: tranzactia a fost stearsa',
    98: 'Nu se poate face aceasta modificare tranzactiei ${cashRegistryTransactionId}: tranzactia nu a fost gasita',
    99: 'Nu se poate face aceasta modificare tranzactiei ${cashRegistryTransactionId}: moneda tranzactiei ${receivedUnit} difera fata de moneda originala ${currentUnit}',
    100: 'Aceasta operațiune produce un sold negativ. Verifică registrul sau modifică tipul operațiunii.',
    101: 'Operațiunea nu a fost înregistrată. Mai încearcă o dată.',
    102: 'Această operațiune a fost ștearsă deja.',
    103: 'Aceasta operațiune produce un sold negativ. Verifică registrul sau modifică tipul operațiunii.',
    104: 'Nu se poate modifica bilantului initial: moneda bilantului initial ${currentUnit} difera fata de moneda noului bilant ${receivedUnit}',
    105: 'Nu se poate face aceasta modificare tranzactiei automatice ${cashRegistryTransactionId}: tranzactia asociata ${checkoutTransactionId} nu este stearsa',
    106: 'Nu se poate initializa registrul de casa: Moneda ${receivedCurrency} nu este suportata',
    107: 'Data selectată pentru aceasta operațiune este mai veche decât a soldului inițial. Verifică registrul sau modifică tipul operațiunii.',
    108: 'Data selectată pentru aceasta operațiune este mai veche decât a soldului inițial. Verifică registrul sau modifică tipul operațiunii.',
    109: 'Compania ${checkoutCompanyId} nu apartine paginii ${pageId}',
    110: 'Registrul de casa ${cashRegistryId} nu apartine companiei ${checkoutCompanyId}',

    //products
    111: 'Produsul trebuie sa aiba cel putin o image in galerie pentru a putea fi vandut online',
    112: 'Nu s-a gasit nicio categorie cu acest id ${categoryId}',
    113: 'Categoriile indicate nu coincid cu cele existente',
    114: 'Nu s-a gasit niciun produs cu acest id ${productId}',
    115: 'Imaginea nu poate fi stearsa. Un produs vandut online trebuie sa aiba cel putin o imagine in galerie',
    116: 'Nu s-a gasit nicio imagine cu acest id ${imageId} in galeria produsului',
    117: 'Produsele indicate nu coincid cu cele existente',
    118: 'Imaginile indicate nu coincid cu cele existente',
    119: 'Nu s-a gasit niciun brand cu acest id ${brandId}',

    //cash registry
    120: 'Nu se poate adauga aceasta tranzactie automatica: lipseste referinta la tranzactia asociata acestei tranzactii',
    121: 'Nu se poate sterge aceasta tranzactie automatica ${cashRegistryTransactionId}: tranzactia asociata ${checkoutTransactionId} nu este stearsa',
    122: 'Nu se poate adauga aceasta tranzactie automatica ${cashRegistryTransactionId}: lipseste referinta la tranzactia asociata acestei tranzactii automatice',
    123: 'Nu se poate face aceasta modificare tranzactiei automatice ${cashRegistryTransactionId}: lipseste referinta la tranzactia asociata acestei tranzactii automatice',
    124: 'Nu se poate initializa registrul de casa: bilantul initial nu poate fi negativ',
    125: 'Nu se poate modifica bilantului initial: bilantul initial nu poate fi negativ',
    126: 'Nu se poate adauga aceasta tranzactie: suma tranzactiei nu poate fi negativ',
    127: 'Nu se poate face aceasta modificare tranzactiei ${cashRegistryTransactionId}: suma tranzactiei nu poate fi negativ',

    128: 'Codul de bare este deja folosit pentru un alt produs',
    129: 'Pagina respectiva a fost mutata',

    130: 'Nu se poate adauga aceasta tranzactie: data tranzactiei ${transactionDate} nu poate fi mai tarzie decat data de astazi',
    131: 'Nu se poate face aceasta modificare tranzactiei ${cashRegistryTransactionId}: data tranzactiei ${transactionDate} nu poate fi mai tarzie decat data de astazi',
    132: 'Produsul {{item.productId}} din gestiunea {{item.inventoryId}} cu cantitatea {{requiredQuantity}} depaseste stocul disponibil de {{availableQuantity}}',
    133: 'Există deja un brand cu același nume',
    134: 'Există deja o categorie cu același nume',
    135: 'Trebuie să introduci un cod fiscal valid, acesta nu corespunde niciunei firme înregistrate',
    136: 'Chestionarul de satisfacție poate fi completat începând cu data ${date}',
    137: 'Pagina ${pageId} nu are o subscripție activă',
    138: 'Scorul trebuie să fie între 0 și 10',
    139: 'Pagină a fost creată în ultimele 30 de zile',
    140: 'Feedback-ul nu trebuie să depășească 1000 de caractere',
    141: 'Numele profilului conține prea multe cuvinte asociate categoriilor MERO',

    //working schedule
    142: 'Programul de lucru din trecut nu poate fi editat.',
    143: 'Programul de lucru se suprapune pentru această zi.',
    144: 'Programul de lucru din trecut nu poate fi șters.',
    145: 'Unul dintre profesioniștii selectați nu aparține acestei pagini.',
    146: 'Nu poți modifica programul de lucru al unui profesionist invitat.',
    147: 'Unul dintre intervalele de lucru este invalid.',
    148: 'Intervalele de lucru se suprapun.',
    149: 'Perioada selectată este invalidă.',
    150: 'Nu am gasit programul de lucru cu acest id.',
  },
};
