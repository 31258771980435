import { JSONable } from './jsonable';
import { DefinedString } from './string';
import * as Ord from 'fp-ts/lib/Ord';
import * as S from 'fp-ts/lib/string';
import * as t from 'io-ts';
import { DateTime } from 'luxon';

export interface DateStringBrand {
  readonly DateString: unique symbol;
}

/**
 * Date string in YYYY-MM-DD format
 */
export type DateString = t.Branded<DefinedString, DateStringBrand>;

/**
 * Decodes a valid DateString from a string in YYYY-MM-DD format
 */
const JSON: t.Type<DateString, JSONable> = t.brand(
  DefinedString,
  (s): s is DateString => /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/.test(s),
  'DateString',
);

/**
 * Get DateString for a {@link Date} value in given {@param timezone}
 */
const fromDate = (date: Date, timezone: string): DateString => {
  return fromDateTime(DateTime.fromJSDate(date, { zone: timezone }), timezone);
};

/**
 * Get DateString for a {@link DateTime} value in given {@param timezone}
 */
const fromDateTime = (date: DateTime, timezone: string): DateString => {
  return date.setZone(timezone).toFormat('yyyy-MM-dd') as DateString;
};

/**
 * Convert {@link DateString} value to a (start of the day) {@link DateTime} at given {@param timezone}
 */
const toDateTime = (dateString: DateString, timezone: string): DateTime => {
  return DateTime.fromFormat(dateString, 'yyyy-MM-dd', {
    zone: timezone,
  });
};

/**
 * Convert {@link DateString} value to a (start of the day) {@link Date} at given {@param timezone}
 */
const toDate = (dateString: DateString, timezone: string): Date => {
  return toDateTime(dateString, timezone).toJSDate();
};

type DateDuration = {
  readonly years?: number;
  readonly months?: number;
  readonly weeks?: number;
  readonly days?: number;
};

/**
 * Add given {@param duration} to {@param dateString}
 */
const plus = (dateString: DateString, duration: DateDuration): DateString => {
  return fromDateTime(toDateTime(dateString, 'UTC').plus(duration), 'UTC');
};

/**
 * Subtract given {@param duration} to {@param dateString}
 */
const minus = (dateString: DateString, duration: DateDuration): DateString => {
  return fromDateTime(toDateTime(dateString, 'UTC').minus(duration), 'UTC');
};

/**
 * @deprecated do not use this! timezone must be explicit when converting a datetime to date string
 * use {@link DateTime.fromDate}
 */
const fromDateOld = (date: Date): DateString => {
  const year = date.getFullYear();

  const month = date.getMonth() + 1;
  const formattedMonth = month < 10 ? `0${month}` : month;

  const day = date.getDate();
  const formattedDay = day < 10 ? `0${day}` : day;

  return `${year}-${formattedMonth}-${formattedDay}` as DateString;
};

/**
 * @deprecated do not use this! timezone must be explicit when converting a date string to datetime
 */
const toDateOld = (dateString: DateString): Date => {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day);
};

/**
 * Check if date string is in the past
 */
const isDateInThePast = (params: {
  readonly date: DateString;
  readonly timezone: string;
  readonly at?: Date;
}): boolean => {
  const { date, timezone, at } = params;

  const currentDate = fromDateTime(
    DateTime.fromJSDate(at ?? new Date(), {
      zone: timezone,
    }),
    timezone,
  );

  return date < currentDate;
};

const ord: Ord.Ord<DateString> = S.Ord;

export const DateString = {
  JSON,
  fromDate,
  fromDateTime,
  toDate,
  toDateTime,
  isDateInThePast,
  ...ord,
  min: Ord.min(ord),
  max: Ord.max(ord),
  lt: Ord.lt(ord),
  gt: Ord.gt(ord),
  plus,
  minus,
};

/**
 * @deprecated use DateString
 */
export const dateStringUtils = {
  /**
   * @deprecated use {@link DateString.fromDate}
   */
  fromDate: fromDateOld,
  /**
   * @deprecated use {@link DateString.toDate}
   */
  toDate: toDate,
  /**
   * @deprecated use {@link DateString.toDate}
   */
  toDateOld: toDateOld,
};
