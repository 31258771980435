import { WorkerId } from '../../../workers/workerId';
import { WorkingScheduleDay } from '../workingScheduleDay';
import { WorkerScheduleId } from '../workingScheduleId';
import { DateString, JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

/**
 * Working schedule exception for a specific date
 * `SpecificDate` schedule CANNOT overlap with other `SpecificDate` schedule, only one should exist.
 * `SpecificDate` schedule CAN overlap with a `Weekly` schedule, to override specific date
 */
export type SpecificDate = {
  readonly _id: WorkerScheduleId;
  readonly workerId: WorkerId;
  readonly type: 'SpecificDate';
  /**
   * Exception date
   */
  readonly date: DateString;
  /**
   * Schedule for specific day
   */
  readonly daySchedule: WorkingScheduleDay;
};

const JSON: t.Type<SpecificDate, JSONable> = t.type(
  {
    _id: WorkerScheduleId.JSON,
    workerId: WorkerId.JSON,
    type: t.literal('SpecificDate'),
    date: DateString.JSON,
    daySchedule: WorkingScheduleDay.JSON,
  },
  'SpecificDateWorkerSchedule',
);

export const SpecificDate = {
  JSON,
};
