import { WorkingScheduleDay } from '../workingScheduleDay';
import { DateString, JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

/**
 * Working schedule exception for a specific date
 */
export type SpecificDate = {
  readonly type: 'SpecificDate';
  /**
   * Schedule date
   */
  readonly date: DateString;
  /**
   * Schedule for specific day
   */
  readonly daySchedule: WorkingScheduleDay;
};

const JSON: t.Type<SpecificDate, JSONable> = t.type(
  {
    type: t.literal('SpecificDate'),
    date: DateString.JSON,
    daySchedule: WorkingScheduleDay.JSON,
  },
  'EditWorkingScheduleSpecificDate',
);

export const SpecificDate = {
  JSON,
};
