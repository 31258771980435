import { InventoryId } from '../pro/inventories/inventoryId';
import { ProductId } from '../pro/products/product/productId';
import { AppointmentDiscount } from './calendarEntry/appointmentDiscount';
import { JSONable, MeroUnits, Money, ScaledNumber, Option, Positive } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type ConsumedProductPayload = {
  readonly _id: ProductId;
  readonly quantity: Positive<ScaledNumber>;
  readonly customPrice: {
    readonly amount: Money<ScaledNumber, MeroUnits.RON>;
    readonly discount: Option<AppointmentDiscount>;
  };
  readonly price: {
    readonly retailPrice: Money<ScaledNumber, MeroUnits.RON>;
    readonly discountedPrice: Money<ScaledNumber, MeroUnits.RON>;
    readonly vatRate: ScaledNumber;
  };
  readonly inventoryId: InventoryId;
};

const moneyC = Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON);

const JSON: t.Type<ConsumedProductPayload, JSONable> = t.type(
  {
    _id: ProductId,
    quantity: ScaledNumber.Positive.JSON,
    customPrice: t.type(
      {
        amount: moneyC,
        discount: Option.json(AppointmentDiscount.JSON),
      },
      'CustomPrice',
    ),
    price: t.type(
      {
        retailPrice: moneyC,
        discountedPrice: moneyC,
        vatRate: ScaledNumber.JSON,
      },
      'Price',
    ),
    inventoryId: InventoryId,
  },
  'ConsumedProductPayload',
);

export const ConsumedProductPayload = {
  JSON,
};
