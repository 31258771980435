import { CheckoutPaymentMethodReportItem } from './checkoutPaymentMethodReportItem';
import { DateString, JSONable, MeroUnits } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutPaymentMethodDailyReportItem<Unit extends MeroUnits.Any> = {
  /**
   * Reported calendar date
   */
  readonly date: DateString;
} & CheckoutPaymentMethodReportItem<Unit>;

const json = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<CheckoutPaymentMethodDailyReportItem<Unit>, JSONable> => {
  return t.intersection(
    [
      t.type({
        date: DateString.JSON,
      }),
      CheckoutPaymentMethodReportItem.json(unit),
    ],
    `CheckoutPaymentMethodDailyReportItem<${unit}>`,
  );
};

export const CheckoutPaymentMethodDailyReportItem = {
  json,
};
