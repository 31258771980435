import { ServiceId } from '../services';
import { UserId } from '../users';
import { WorkerId } from '../workers';
import { WaitingListId } from './waiting-list-id';
import { DateString, JSONable, optionull } from '@mero/shared-sdk';
import * as Nea from 'fp-ts/lib/NonEmptyArray';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

export type WaitingList = {
  readonly date: DateString;
  readonly forWorkerId: WorkerId;
  readonly userId: UserId;
  readonly _id: WaitingListId;
  readonly serviceIds: Nea.NonEmptyArray<ServiceId> | undefined;
};

const JSON: t.Type<WaitingList, JSONable> = t.type(
  {
    date: DateString.JSON,
    forWorkerId: WorkerId.JSON,
    userId: UserId,
    _id: WaitingListId,
    serviceIds: optionull(tt.nonEmptyArray(ServiceId)),
  },
  'AvailableDay',
);

export const WaitingList = {
  JSON,
};
