import { JSONable } from './jsonable';
import * as t from 'io-ts';

export interface MinuteBrand {
  readonly Minute: unique symbol;
}

export type Minute = t.Branded<t.Int, MinuteBrand>;

const is = (minute: t.Int): minute is Minute => {
  return minute >= 0 && minute <= 59;
};

const JSON: t.Type<Minute, JSONable, unknown> = t.brand(t.Int, is, 'Minute');

export const Minute = {
  JSON,
};
