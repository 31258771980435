import { Unit } from './unit';
import { PositiveInt, Numbers, JSONable, ScaledNumber, Positive } from '@mero/shared-sdk';
import * as t from 'io-ts';

export { Spec } from './spec';
export { Unit } from './unit';

export type Any = {
  readonly unit: Unit;
  readonly value: Positive<ScaledNumber>;
  readonly exponent: PositiveInt | Numbers.Zero;
};

export const JSON: t.Type<Any, JSONable> = t.type(
  {
    unit: Unit.JSON,
    value: ScaledNumber.Positive.JSON,
    exponent: t.union([PositiveInt.JSON, Numbers.Zero]),
  },
  `ProductMeasure`,
);
