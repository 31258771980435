import { PageRoleId } from './page-role-id';
import { JSONable, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type PageRolePreview = {
  /**
   * Unique role identifier
   */
  readonly id: PageRoleId;
  /**
   * Role name (to display to users)
   */
  readonly name: string;
  /**
   * When set to true - means role assumes a worker profile
   */
  readonly isWorkerRole: Option<boolean>;
  /**
   * When set to true - means role assumes an account owner
   */
  readonly isOwnerRole: Option<boolean>;
};

const JSON: t.Type<PageRolePreview, JSONable> = t.strict(
  {
    id: PageRoleId,
    name: t.string,
    isWorkerRole: Option.json(t.boolean),
    isOwnerRole: Option.json(t.boolean),
  },
  'PageRolePreview',
);

export const PageRolePreview = {
  JSON,
};
