import { PageIdC } from '../../../pages/pagesHttpClient/pagesJson';
import { CreateProductPayload } from '../product/createProductPayload';
import { ImportProductsResponse } from '../product/importProductsResponse';
import { Product } from '../product/product';
import { ProductAvailability } from '../product/productAvailability';
import { ProductBarcode } from '../product/productBarcode';
import { ProductGallery } from '../product/productGallery';
import { ProductId } from '../product/productId';
import { ProductMeasure } from '../product/productMeasure';
import { ProductPrice } from '../product/productPrice';
import { ProductPriceId } from '../product/productPriceId';
import { ProductSavedPrice } from '../product/productSavedPrice';
import { ProductCountersResult } from '../product/productSearch/productCountersResult';
import { ProductSearchType, SortField, AvailabilityFilter } from '../product/productSearch/productsSearchType';
import { ProductStatus } from '../product/productStatus';
import { ReorderProducts } from '../product/reorderProducts';
import { UpdateProductPayload } from '../product/updateProductPayload';
import { ProductBrand } from '../productBrand/productBrand';
import { ProductBrandId } from '../productBrand/productBrandId';
import { ProductSearchResult, ProductSearchHit } from '../productCategory';
import { ProductCategory } from '../productCategory/productCategory';
import { ProductCategoryId } from '../productCategory/productCategoryId';
import {
  JSONable,
  ObjectId,
  HasId,
  ProfileImage,
  Barcode,
  ScaledNumber,
  MeroUnits,
  Money,
  DefinedTrimedString,
  SortDirection,
  PositiveInt,
  Numbers,
} from '@mero/shared-sdk';
import { optionull } from '@mero/shared-sdk';
import { PortionPercentScaledNumber } from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

const moneyC: t.Type<Money<ScaledNumber, MeroUnits.RON>, JSONable> = Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON);

export const ProductIdC: t.Type<ProductId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is ProductId => true,
  'ProductId',
);

export const ProductPriceIdC: t.Type<ProductPriceId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is ProductPriceId => true,
  'ProductPriceId',
);

export const HasProductPriceIdC: t.Type<HasId<ProductPriceId>, JSONable> = t.type(
  {
    _id: ProductPriceIdC,
  },
  'HasProductPriceId',
);

export const ProductCategoryIdC: t.Type<ProductCategoryId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is ProductCategoryId => true,
  'ProductCategoryId',
);

export const ProductBrandIdC: t.Type<ProductBrandId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is ProductBrandId => true,
  'ProductBrandId',
);

export const ProductBrandC: t.Type<ProductBrand, JSONable> = t.intersection(
  [
    t.type(
      {
        _id: ProductBrandIdC,
        name: DefinedTrimedString,
        pageId: PageIdC,
      },
      '!',
    ),
    t.partial(
      {
        image: ProfileImage,
      },
      '?',
    ),
  ],
  'ProductBrand',
);

export const ProductBrandsArrayC: t.Type<ProductBrand[], JSONable> = t.array(ProductBrandC, 'ProductBrandsArray');

export const ProductBarcodeC: t.Type<ProductBarcode, JSONable> = Barcode.JSON;

export const ProductGalleryC: t.Type<ProductGallery, JSONable> = t.array(
  t.type(
    {
      order: t.number,
      image: ProfileImage,
      keywords: t.array(t.string),
    },
    'ProductGalleryItem',
  ),
  'ProductGallery',
);

export const ProductPriceC: t.Type<ProductPrice, JSONable> = t.type(
  {
    retailPrice: moneyC,
    discountedPrice: moneyC,
    vatRate: PortionPercentScaledNumber.JSON,
  },
  'ProductPrice',
);

export const ProductSavedPriceC: t.Type<ProductSavedPrice, JSONable> = t.intersection(
  [HasProductPriceIdC, ProductPriceC],
  'ProductSavedPrice',
);

export const ProfileImageC: t.Type<ProfileImage, JSONable> = ProfileImage;

export const ProductC: t.Type<Product, JSONable> = t.exact(
  t.intersection(
    [
      t.type(
        {
          _id: ProductIdC,
          name: DefinedTrimedString,
          measure: ProductMeasure.JSON,
          status: ProductStatus.JSON,
          gallery: ProductGalleryC,
          availability: ProductAvailability.JSON,
          price: ProductSavedPriceC,
          pageId: PageIdC,
          category: t.intersection(
            [
              t.type(
                {
                  order: t.number,
                },
                '!',
              ),
              t.partial(
                {
                  categoryId: ProductCategoryIdC,
                },
                '?',
              ),
            ],
            'Category',
          ),
        },
        '!',
      ),
      t.partial(
        {
          description: DefinedTrimedString,
          brandId: ProductBrandIdC,
          barcode: ProductBarcodeC,
        },
        '?',
      ),
    ],
    `Product`,
  ),
  'Exact<Product>',
);

export const ProductsArrayC: t.Type<Product[], JSONable> = t.array(ProductC, 'ProductsArray');

export const ProductCategoryC: t.Type<ProductCategory, JSONable> = t.type(
  {
    _id: ProductCategoryIdC,
    name: DefinedTrimedString,
    order: t.number,
    pageId: PageIdC,
  },
  'ProductCategory',
);

export const ProductCategoriesArrayC: t.Type<ProductCategory[], JSONable> = t.array(
  ProductCategoryC,
  'CategoriesArray',
);

export const ProductSearchHitC: t.Type<ProductSearchHit, JSONable> = t.intersection(
  [
    t.type(
      {
        _id: ProductIdC,
        name: DefinedTrimedString,
        measure: ProductMeasure.JSON,
        price: ProductSavedPriceC,
        isStockManagementEnabled: t.boolean,
        stockDescription: t.type(
          {
            stock: ScaledNumber.NonNegative.JSON,
            measureUnit: ProductMeasure.Unit.JSON,
            measureValue: ScaledNumber.Positive.JSON,
            quantity: ScaledNumber.NonNegative.JSON,
          },
          'StockDescription',
        ),
      },
      '!',
    ),
    t.partial(
      {
        description: DefinedTrimedString,
        brandName: DefinedTrimedString,
        categoryName: DefinedTrimedString,
        categoryId: ProductCategoryIdC,
        barcode: ProductBarcodeC,
        image: ProfileImageC,
      },
      '?',
    ),
  ],
  'SearchProduct',
);

export const ProductSearchHitsC: t.Type<ProductSearchHit[], JSONable> = t.array(
  ProductSearchHitC,
  'ProductSearchHitsC',
);

export const ProductSearchResultC: t.Type<ProductSearchResult, JSONable> = t.type(
  {
    products: t.array(ProductSearchHitC, 'ProductsArray'),
    next: optionull(t.string),
  },
  'ProductsResult',
);

export const ProductCountersResultC: t.Type<ProductCountersResult, JSONable> = t.type(
  {
    all: t.number,
    active: t.number,
    inactive: t.number,
    categories: t.record(ProductCategoryIdC, t.number),
    brands: t.record(ProductBrandIdC, t.number),
    noCategory: t.number,
    noBrand: t.number,
  },
  'ProductCountersResult',
);

export const ImportProductsC: t.Type<ImportProductsResponse, JSONable> = ImportProductsResponse.JSON;

export const CreateProductPayloadC: t.Type<CreateProductPayload, JSONable> = t.intersection(
  [
    t.type(
      {
        name: DefinedTrimedString,
        status: t.union([ProductStatus.Active.JSON, ProductStatus.Inactive.JSON], 'ProductStatus'),
        availability: ProductAvailability.JSON,
        price: ProductPriceC,
        measure: t.type(
          {
            unit: ProductMeasure.Unit.JSON,
            value: ScaledNumber.Positive.JSON,
          },
          `ProductMeasure`,
        ),
      },
      '!',
    ),
    t.partial(
      {
        description: DefinedTrimedString,
        categoryId: ProductCategoryIdC,
        brandId: ProductBrandIdC,
        barcode: ProductBarcodeC,
      },
      '?',
    ),
  ],
  'CreateProductPayload',
);

export const UpdateProductPayloadC: t.Type<UpdateProductPayload, JSONable> = t.intersection(
  [
    t.type(
      {
        name: DefinedTrimedString,
        status: t.union([ProductStatus.Active.JSON, ProductStatus.Inactive.JSON], 'ProductStatus'),
        availability: ProductAvailability.JSON,
        price: ProductPriceC,
      },
      '!',
    ),
    t.partial(
      {
        description: DefinedTrimedString,
        categoryId: ProductCategoryIdC,
        brandId: ProductBrandIdC,
        barcode: ProductBarcodeC,
      },
      '?',
    ),
  ],
  'UpdateProductPayload',
);

export const ProductMeasureSpecC: t.Type<ProductMeasure.Spec[], JSONable> = t.array(
  t.type(
    {
      name: t.string,
      unit: ProductMeasure.Unit.JSON,
      exponent: t.union([PositiveInt.JSON, Numbers.Zero]),
      order: t.number,
      implicitValue: optionull(PositiveInt.JSON),
    },
    'ProductMeasure.Spec',
  ),
);

export const ReorderProductsC: t.Type<ReorderProducts, JSONable> = t.type(
  {
    categories: t.array(
      t.type(
        {
          categoryId: ProductCategoryIdC,
          products: t.array(ProductIdC, 'ProductIdArray'),
        },
        'CategoryProducts',
      ),
      'Categories',
    ),
    other: t.array(ProductIdC, 'ProductIdArray'),
  },
  'ReorderProducts',
);

export const ProductSearchTypeC: t.Type<ProductSearchType, JSONable> = t.intersection(
  [
    t.partial(
      {
        search: t.string,
        sortBy: SortField.JSON,
        sortDirection: SortDirection.JSON,
        categoryId: t.union([ProductCategoryIdC, t.literal('none')], 'CategoryId'),
        brandId: t.union([ProductBrandIdC, t.literal('none')], 'brandId'),
        page: optionull(t.string),
        status: ProductStatus.JSON,
        availability: AvailabilityFilter.JSON,
        stockValue: t.union([t.literal('any'), t.literal('positive')]),
      },
      '?',
    ),
    t.type(
      {
        limit: tt.NumberFromString,
      },
      '!',
    ),
  ],
  'ProductSearchType',
);
