import { PageRolePreview } from './page-role-preview';
import { PagePermissionOverview } from './pagePermissionOverview';
import * as t from 'io-ts';

/**
 * @deprecated, use PageRoleOverview2
 */
export const PageRoleOverview = t.intersection(
  [
    PageRolePreview.JSON,
    t.type({
      permissionsOverview: t.array(PagePermissionOverview.JSON),
    }),
  ],
  'PageRoleOverview',
);

export interface PageRoleOverview extends t.TypeOf<typeof PageRoleOverview> {}
