import { DateBusinessHours } from '../pro/workingSchedule/dateBusinessHours';
import { WorkingScheduleDay } from '../pro/workingSchedule/workingScheduleDay';
import { WorkerId } from '../workers';
import { BulkDailyBounds } from './bulkDailyBounds';
import { CalendarId } from './calendar-id';
import { CalendarEntry } from './calendarEntry';
import { DateInterval, DayTime, HasId, JSONable, Timeline, optionull, DateString } from '@mero/shared-sdk';
import * as t from 'io-ts';
import { DateTime } from 'luxon';

export type BulkCalendarData = {
  readonly worker: HasId<WorkerId> & {
    readonly calendar: HasId<CalendarId>;
  };
  readonly entries: CalendarEntry.Any[];
  readonly hasWorkingHours: boolean;
  /**
   * DayTime that fits all the appointments and working hours
   */
  readonly activeDailyBounds: BulkDailyBounds | undefined;
};

const JSON: t.Type<BulkCalendarData, JSONable> = t.type(
  {
    worker: t.intersection(
      [
        HasId.json(WorkerId.JSON),
        t.type(
          {
            calendar: HasId.json(CalendarId),
          },
          '!',
        ),
      ],
      'Worker',
    ),
    entries: t.array(CalendarEntry.Any.JSON),
    hasWorkingHours: t.boolean,
    activeDailyBounds: optionull(BulkDailyBounds.JSON),
  },
  'BulkCalendarData',
);

/**
 * Compute working hour events for the given period
 * @returns working hour events as closed intervals
 */
const computeWorkingHoursEvents = (
  period: DateInterval,
  businessHours: DateBusinessHours[],
  timezone: string,
): readonly DateInterval[] => {
  const workingHoursEvents: DateInterval[] = [];

  const periodFrom = DateTime.fromJSDate(period.from, { zone: timezone });
  const periodTo = DateTime.fromJSDate(period.to, { zone: timezone });

  let dayStart = periodFrom.startOf('day');

  while (dayStart < periodTo) {
    const dateString = DateString.fromDateTime(dayStart, timezone);
    const dateBusinessHours =
      businessHours.find((businessHoursDay) => businessHoursDay.date === dateString)?.schedule ??
      WorkingScheduleDay.INACTIVE;

    if (dateBusinessHours.active) {
      if (WorkingScheduleDay.isWholeDay(dateBusinessHours)) {
        const workingFrom = dayStart.startOf('day');
        const workingTo = dayStart.endOf('day');
        workingHoursEvents.push(
          DateInterval.of(
            // stay inside the period
            (workingFrom < periodFrom ? periodFrom : workingFrom).toJSDate(),
            (workingTo > periodTo ? periodTo : workingTo).toJSDate(),
          ),
        );
      } else {
        for (const interval of dateBusinessHours.intervals) {
          const workingFrom = dayStart.set({
            hour: interval.from.hour,
            minute: interval.from.minute,
            second: 0,
            millisecond: 0,
          });

          const workingTo = DayTime.equals(interval.to, DayTime.of(0, 0))
            ? dayStart.endOf('day')
            : dayStart
                .set({
                  hour: interval.to.hour,
                  minute: interval.to.minute,
                  second: 0,
                  millisecond: 0,
                })
                .minus({ milliseconds: 1 }); // working hours are right-open, convert to right-closed

          workingHoursEvents.push(
            DateInterval.of(
              // stay inside the period
              (workingFrom < periodFrom ? periodFrom : workingFrom).toJSDate(),
              (workingTo > periodTo ? periodTo : workingTo).toJSDate(),
            ),
          );
        }
      }
    }

    // next day
    dayStart = dayStart.plus({ days: 1 });
  }

  return workingHoursEvents;
};

/**
 * Compute if there are working hours in the given period
 * @param period selected period
 * @param businessHours calendar business hours for each day from selected period
 * @param timezone calendar timezone to use for local time calculations
 * @param blockedTime blocked time entries
 */
const computeHasWorkingHours = (
  period: DateInterval,
  businessHours: DateBusinessHours[],
  timezone: string,
  blockedTime: Pick<CalendarEntry.BlockedTime, 'type' | 'start' | 'end'>[],
): boolean => {
  // Just to make sure there are no infinite loops
  if (period.from > period.to) {
    return false;
  }

  // working hours closed intervals
  const workingHoursEvents = computeWorkingHoursEvents(period, businessHours, timezone);

  let timeline = Timeline.of(workingHoursEvents);

  if (Timeline.isEmpty(timeline)) {
    // no working hours
    return false;
  }

  for (const entry of blockedTime) {
    timeline = Timeline.subInterval(
      timeline,
      DateInterval.of(
        entry.start,
        // calendar entries are left-closed right-open. convert to closed interval
        new Date(entry.end.getTime() - 1),
      ),
    );

    if (Timeline.isEmpty(timeline)) {
      return false;
    }
  }

  return true;
};

/**
 * Compute minimal daily bounds that fits all the entries
 * @param period selected period (closed interval)
 * @param businessHours calendar business hours for each day from selected period
 * @param timezone calendar timezone to use for local time calculations
 * @param appointments blocked time entries
 */
const computeDailyBounds = (
  period: DateInterval,
  businessHours: DateBusinessHours[],
  timezone: string,
  blockedTime: Pick<CalendarEntry.BlockedTime, 'type' | 'start' | 'end'>[],
  appointments: Pick<CalendarEntry.Appointment, 'type' | 'start' | 'end'>[],
): BulkDailyBounds | undefined => {
  // Just to make sure there are no infinite loops
  if (period.from > period.to) {
    return undefined;
  }

  // working hours closed intervals
  const workingHoursEvents = computeWorkingHoursEvents(period, businessHours, timezone);

  let timeline = Timeline.of(workingHoursEvents);

  // Subtract blocked time
  for (const entry of blockedTime) {
    timeline = Timeline.subInterval(
      timeline,
      DateInterval.of(
        entry.start,
        // calendar entries are left-closed right-open. convert to closed interval
        new Date(entry.end.getTime() - 1),
      ),
    );
  }

  // Add appointments
  for (const entry of appointments) {
    timeline = Timeline.addInterval(
      timeline,
      DateInterval.of(
        entry.start,
        // calendar entries are left-closed right-open. convert to closed interval
        new Date(entry.end.getTime() - 1),
      ),
    );
  }

  if (Timeline.isEmpty(timeline)) {
    return undefined;
  }

  let bounds: BulkDailyBounds | undefined = undefined;

  const periodFrom = DateTime.fromJSDate(period.from, { zone: timezone });
  const periodFromDayTime = DayTime.of(periodFrom.hour, periodFrom.minute);

  const periodTo = DateTime.fromJSDate(period.to, { zone: timezone });
  const periodToDayTime = DayTime.of(periodTo.hour, periodTo.minute);

  for (const interval of timeline.events) {
    const intervalFrom = DateTime.fromJSDate(interval.from, { zone: timezone });
    const intervalTo = DateTime.fromJSDate(interval.to, { zone: timezone });

    if (intervalTo < periodFrom || intervalFrom > periodTo) {
      // Filter out entries that are out of selected period. Timeline events are closed intervals
      continue;
    }

    const intervalFromDayTime = DayTime.of(intervalFrom.hour, intervalFrom.minute);
    const intervalToDayTime = DayTime.of(intervalTo.hour, intervalTo.minute);

    // Constrain entries start/end to the selected period
    const from = intervalFrom < periodFrom ? periodFromDayTime : intervalFromDayTime;
    const to = intervalTo > periodTo ? periodToDayTime : intervalToDayTime;

    const intervalBounds = BulkDailyBounds.of(from, to);

    bounds = bounds ? BulkDailyBounds.merge(bounds, intervalBounds) : intervalBounds;

    if (BulkDailyBounds.isFullDay(bounds)) {
      // no need to continue
      return bounds;
    }
  }

  return bounds;
};

export const BulkCalendarData = {
  JSON,
  computeHasWorkingHours,
  computeDailyBounds,
};
