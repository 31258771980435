import { Any } from './any';
import { SpecificDate } from './specificDate';
import { Weekly } from './weekly';

export * from './weekly';
export * from './specificDate';
export * from './any';

export const isWeekly = (schedule: Any): schedule is Weekly => {
  return schedule.type === 'Weekly';
};

export const isSpecificDate = (schedule: Any): schedule is SpecificDate => {
  return schedule.type === 'SpecificDate';
};
