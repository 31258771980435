import { WorkerId } from '../../../workers/workerId';
import { WeekDaysSchedule } from '../weekDaysSchedule';
import { WorkerScheduleId } from '../workingScheduleId';
import { DateString, JSONable, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

/**
 * `Weekly` schedule repeats every X weeks, based on `weekSchedules` properties, ex. for
 * - one element in `weekSchedules` - repeats weekly
 * - two elements  in `weekSchedules` - repeats every 2 weeks.
 *
 * `start` date defines the time when the schedule starts and first element in `weekSchedule` defines
 *  the schedule for the week of `start` date.
 *
 *  `end` date defines the time when the schedule ends. If end is not defined - schedule repeats indefinitely.
 *
 * `Weekly` schedule is applied strictly between `start` and `end` dates. If start is on Friday -
 *  Friday is the first day of that week when this schedule is applied
 */
export type Weekly = {
  readonly _id: WorkerScheduleId;
  readonly workerId: WorkerId;
  readonly type: 'Weekly';
  /**
   * Start date for worker schedule recurrence
   */
  readonly start: DateString;
  /**
   * End date for worker schedule recurrence
   */
  readonly end: Option<DateString>;
  /**
   * Schedule for each week of the schedule
   */
  readonly weekSchedules: WeekDaysSchedule[];
};

/**
 * Get index of schedule from weekSchedules
 * that applies to given date
 */
const getWeekScheduleIndexForDate = (params: { readonly schedule: Weekly; readonly date: DateString }): number => {
  const weeklyWorkingSchedule = params.schedule;
  const timezone = 'UTC';

  if (
    params.date < weeklyWorkingSchedule.start ||
    (weeklyWorkingSchedule.end && params.date > weeklyWorkingSchedule.end)
  ) {
    throw new Error(`Schedule is not active on date`);
  }

  const date = DateString.toDateTime(params.date, timezone);
  const workingScheduleStart = DateString.toDateTime(weeklyWorkingSchedule.start, timezone);

  const weeksDifference = Math.floor(
    date.diff(
      workingScheduleStart.minus({
        days: workingScheduleStart.weekday - 1,
      }),
      'weeks',
    ).weeks,
  );
  const weekIndex = weeksDifference % weeklyWorkingSchedule.weekSchedules.length;

  return weekIndex;
};

const JSON: t.Type<Weekly, JSONable> = t.type(
  {
    _id: WorkerScheduleId.JSON,
    workerId: WorkerId.JSON,
    type: t.literal('Weekly'),
    start: DateString.JSON,
    end: Option.json(DateString.JSON),
    weekSchedules: t.array(WeekDaysSchedule.JSON),
  },
  'WeeklyWorkerSchedule',
);

export const Weekly = {
  getWeekScheduleIndexForDate,
  JSON,
};
