import { DayTime } from '@mero/shared-sdk';
import * as Eq from 'fp-ts/Eq';
import * as B from 'fp-ts/boolean';
import * as t from 'io-ts';

/**
 * A left-closed, right-open working interval of time in a day
 * ex. if to is 18:00 it means at 18:00 the worker is not working anymore
 *
 * Full day is covered by wholeDay flag
 */
export type WorkInterval = {
  readonly from: DayTime;
  readonly to: DayTime;
  readonly active: boolean;
  readonly wholeDay: boolean;
};

const JSON = t.type(
  {
    from: DayTime.JSON,
    to: DayTime.JSON,
    active: t.boolean,
    wholeDay: t.boolean,
  },
  'WorkInterval',
);

const eq: Eq.Eq<WorkInterval> = Eq.struct<WorkInterval>({
  from: DayTime,
  to: DayTime,
  active: B.Eq,
  wholeDay: B.Eq,
});

const DAY_START = DayTime.of(0, 0);

/**
 * WARNING: 23:59 is not actually correct value for the `right-open` interval, but FE
 *  does not support 00:00 -> 00:00 interval, and whole day is covered by wholeDay flag
 */
const DAY_END = DayTime.of(23, 59);

const FULLDAY_INACTIVE: WorkInterval = {
  from: DAY_START,
  to: DAY_END,
  active: false,
  wholeDay: true,
};

const FULLDAY_ACTIVE: WorkInterval = {
  from: DAY_START,
  to: DAY_END,
  active: true,
  wholeDay: true,
};

export const WorkInterval = {
  JSON,
  ...eq,
  FULLDAY_INACTIVE,
  FULLDAY_ACTIVE,
};
