import * as t from 'io-ts';

export interface WorkerScheduleIdBrand {
  readonly WorkerScheduleId: unique symbol;
}

export type WorkerScheduleId = t.Branded<string, WorkerScheduleIdBrand>;

const JSON: t.Type<WorkerScheduleId, string> = t.brand(
  t.string,
  (_id: string): _id is WorkerScheduleId => true,
  'WorkerScheduleId',
);

export const WorkerScheduleId = {
  JSON,
};
