import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type PagePermissionOverview = {
  /**
   * Permission title
   */
  readonly title: string;
  readonly isActive: boolean;
};

const JSON: t.Type<PagePermissionOverview, JSONable> = t.type(
  {
    title: t.string,
    isActive: t.boolean,
  },
  'PagePermissionOverview',
);

export const PagePermissionOverview = {
  JSON,
};
