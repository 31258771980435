import { PageRolePreview } from './page-role-preview';
import { PagePermissionOverview } from './pagePermissionOverview';
import { PagePermissionsGroupOverview } from './pagePermissionsGroupOverview';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type PageRoleOverview2 = PageRolePreview & {
  readonly permissionsOverview: {
    /**
     * Permission category title
     */
    readonly title: string;
    readonly permissions: PagePermissionOverview[];
  }[];
};

const JSON: t.Type<PageRoleOverview2, JSONable> = t.intersection(
  [
    PageRolePreview.JSON,
    t.type({
      permissionsOverview: t.array(PagePermissionsGroupOverview.JSON),
    }),
  ],
  'PageRoleOverview2',
);

export const PageRoleOverview2 = {
  JSON,
};
