import { Result, ok, err } from '../common/result';
import { ExtendedCalculator } from './extendedCalculator';
import { NonNegative } from './nonNegative';
import { NonPositive } from './nonPositive';
import * as t from 'io-ts';

export type Zero<Num> = NonNegative<Num> & NonPositive<Num>;

export type ZeroModule<Num> = {
  /**
   * Checks if value is a valid Zero
   */
  readonly is: (a: Num) => a is Zero<Num>;
  /**
   * Parses a Num to a Zero or returns an error.
   */
  readonly from: (n: Num) => Result<Zero<Num>, Error>;
  /**
   * Parses a Num to a Zero or throws when invalid.
   */
  readonly unsafeFrom: (n: Num) => Zero<Num>;
  /**
   * Build new JSON codec for Zero<Num>
   */
  readonly json: <O, I>(codec: t.Type<Num, O, I>) => t.Type<Zero<Num>, O, I>;
};

const build = <Num>(num: ExtendedCalculator<Num>): ZeroModule<Num> => {
  const is = (a: Num): a is Zero<Num> => {
    return num.equals(a, num.zero());
  };

  const from = (n: Num): Result<Zero<Num>, Error> => {
    if (!is(n)) {
      return err(new Error('Invalid Zero<Num> value'));
    }

    return ok(n);
  };

  const unsafeFrom = (n: Num): Zero<Num> => {
    if (!is(n)) {
      throw new Error('Invalid Zero<Num> value');
    }

    return n;
  };

  const json = <O, I>(codec: t.Type<Num, O, I>): t.Type<Zero<Num>, O, I> => {
    return t.brand(codec, is, 'NonPositive');
  };

  return {
    is,
    from,
    unsafeFrom,
    json,
  };
};

export const Zero = {
  build,
};
