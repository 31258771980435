declare const ValidatedTag: unique symbol;

export type Validated<A> = A & {
  readonly [ValidatedTag]: unique symbol;
};

const of = <A>(value: A): Validated<A> => {
  return value as Validated<A>;
};

/**
 * @deprecated use `Validated.of`
 */
export const validated = of;

export const Validated = {
  of,
};
