import { AppointmentId } from '../../calendar';
import { WorkingScheduleDay } from './workingScheduleDay';
import { DateString, DayTime, JSONable, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

/**
 * Working schedule slots for specific date
 * includes schedule intervals and blocked time records
 */
export type DateWorkingSchedule = {
  readonly date: DateString;
  /**
   * Schedule records for date
   */
  readonly records: (
    | {
        readonly type: 'Schedule';
        readonly schedule: WorkingScheduleDay;
      }
    | {
        readonly type: 'BlockedTime';
        readonly blockedTime: {
          readonly _id: AppointmentId;
          /**
           * Blocked time adjusted to fit current date
           * Interval is right open
           */
          readonly interval: {
            readonly from: DayTime;
            readonly to: DayTime;
          };
          readonly occurrenceIndex: Option<number>;
        };
      }
  )[];
};

const JSON: t.Type<DateWorkingSchedule, JSONable> = t.type(
  {
    date: DateString.JSON,
    records: t.array(
      t.union(
        [
          t.type(
            {
              type: t.literal('Schedule'),
              schedule: WorkingScheduleDay.JSON,
            },
            'WorkingScheduleRecord',
          ),
          t.type(
            {
              type: t.literal('BlockedTime'),
              blockedTime: t.type(
                {
                  _id: AppointmentId,
                  interval: t.type(
                    {
                      from: DayTime.JSON,
                      to: DayTime.JSON,
                    },
                    'Interval',
                  ),
                  occurrenceIndex: Option.json(t.number),
                },
                'BlockedTime',
              ),
            },
            'BlockedTimeScheduleRecord',
          ),
        ],
        'ScheduleRecord',
      ),
      'ScheduleRecords',
    ),
  },
  'DayWorkingSchedule',
);

/**
 * Get total duration for working schedules (in minutes)
 */
const getTotalDuration = (schedule: DateWorkingSchedule[]): number => {
  return schedule.reduce(
    (totalDuration, dateSchedule) =>
      totalDuration +
      dateSchedule.records.reduce(
        (dateTotalDuration, record) =>
          dateTotalDuration + (record.type === 'Schedule' ? WorkingScheduleDay.getDuration(record.schedule) : 0),
        0,
      ),
    0,
  );
};

export const DateWorkingSchedule = {
  getTotalDuration,
  JSON,
};
