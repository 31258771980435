import { WorkScheduleInterval } from './workScheduleInterval';
import { DayTime, JSONable } from '@mero/shared-sdk';
import * as Nea from 'fp-ts/lib/NonEmptyArray';
import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

export type WorkingScheduleDay =
  | {
      readonly active: true;
      readonly intervals: Nea.NonEmptyArray<WorkScheduleInterval>;
    }
  | {
      readonly active: false;
    };

const JSON: t.Type<WorkingScheduleDay, JSONable> = t.union(
  [
    t.type(
      {
        active: t.literal(false),
      },
      'InactiveWorkingDay',
    ),
    t.type(
      {
        active: t.literal(true),
        intervals: tt.nonEmptyArray(WorkScheduleInterval.JSON),
      },
      'ActiveWorkingDay',
    ),
  ],
  'WorkingScheduleDay',
);

const areIntervalsValid = (intervals: WorkScheduleInterval[]): boolean => {
  if (intervals.length) {
    if (!intervals.every((interval) => WorkScheduleInterval.isValid(interval))) {
      return false;
    }

    let sortedIntervals = intervals;
    if (intervals.length > 1) {
      sortedIntervals = intervals.sort((intervalA, intervalB) => DayTime.compare(intervalA.from, intervalB.from));

      //check if intervals overlap each other
      for (let index = 0; index < sortedIntervals.length - 1; index++) {
        if (DayTime.gt(sortedIntervals[index].to, sortedIntervals[index + 1].from)) {
          return false;
        }
      }
    }
  }

  return true;
};

/**
 * Create working schedule day from intervals
 */
const unsafeFromIntervals = (
  intervals: {
    readonly from: DayTime;
    readonly to: DayTime;
  }[],
): WorkingScheduleDay => {
  if (intervals.length) {
    if (!areIntervalsValid(intervals)) {
      throw new Error(`Schedule intervals are invalid`);
    }

    const sortedIntervals = intervals.sort((intervalA, intervalB) => DayTime.compare(intervalA.from, intervalB.from));

    const scheduleIntervals = pipe(
      sortedIntervals,
      Nea.fromArray,
      O.getOrElseW(() => {
        throw new Error(`Failed to decode NonEmptyArray`);
      }),
    );

    return {
      active: true,
      intervals: scheduleIntervals,
    };
  }

  return INACTIVE;
};

const INACTIVE: WorkingScheduleDay = {
  active: false,
};

const equals = (a: WorkingScheduleDay, b: WorkingScheduleDay): boolean => {
  if (a.active && b.active) {
    return (
      a.intervals.length === b.intervals.length &&
      a.intervals.every((interval, index) => WorkScheduleInterval.equals(interval, b.intervals[index]))
    );
  }

  return a.active === b.active;
};

const getDuration = (workingScheduleDay: WorkingScheduleDay): number => {
  if (workingScheduleDay.active) {
    return workingScheduleDay.intervals.reduce(
      (duration, interval) => duration + WorkScheduleInterval.getDuration(interval),
      0,
    );
  }

  return 0;
};

const isWholeDay = (workingScheduleDay: WorkingScheduleDay): boolean => {
  return getDuration(workingScheduleDay) === 24 * 60;
};

export const WorkingScheduleDay = {
  areIntervalsValid,
  equals,
  getDuration,
  isWholeDay,
  unsafeFromIntervals,
  JSON,
  INACTIVE,
};
