import { Result, ok, err } from '../common/result';
import { ExtendedCalculator } from './extendedCalculator';
import * as t from 'io-ts';

export interface NonNegativeBrand {
  readonly NonNegative: unique symbol;
}

export type NonNegative<Num> = t.Branded<Num, NonNegativeBrand>;

export type NonNegativeModule<Num> = {
  /**
   * Checks if value is a valid NonNegative
   */
  readonly is: (a: Num) => a is NonNegative<Num>;
  /**
   * Parses a Num to a NonNegative or returns an error.
   */
  readonly from: (n: Num) => Result<NonNegative<Num>, Error>;
  /**
   * Parses a Num to a NonNegative or throws when invalid.
   */
  readonly unsafeFrom: (n: Num) => NonNegative<Num>;
  /**
   * Build new JSON codec for NonNegative<Num>
   */
  readonly json: <O, I>(codec: t.Type<Num, O, I>) => t.Type<NonNegative<Num>, O, I>;
};

const build = <Num>(num: Pick<ExtendedCalculator<Num>, 'greaterThanOrEqual' | 'zero'>): NonNegativeModule<Num> => {
  const is = (a: Num): a is NonNegative<Num> => {
    return num.greaterThanOrEqual(a, num.zero());
  };

  const from = (n: Num): Result<NonNegative<Num>, Error> => {
    if (!is(n)) {
      return err(new Error('Invalid NonNegative<Num> value'));
    }

    return ok(n);
  };

  const unsafeFrom = (n: Num): NonNegative<Num> => {
    if (!is(n)) {
      throw new Error('Invalid NonNegative<Num> value');
    }

    return n;
  };

  const json = <O, I>(codec: t.Type<Num, O, I>): t.Type<NonNegative<Num>, O, I> => {
    return t.brand(codec, is, `NonNegative`);
  };

  return {
    is,
    from,
    unsafeFrom,
    json,
  };
};

export const NonNegative = {
  build,
};
