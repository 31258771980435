import { SpecificDate } from './specificDate';
import { Weekly } from './weekly';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Any = SpecificDate | Weekly;

const JSON: t.Type<Any, JSONable> = t.union([Weekly.JSON, SpecificDate.JSON], 'EditWorkingSchedule');

export const Any = {
  JSON,
};
