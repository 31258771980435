import { Result, ok, err } from '../common/result';
import { ExtendedCalculator } from './extendedCalculator';
import { NonPositive } from './nonPositive';
import { NonZero } from './nonZero';
import * as t from 'io-ts';

export type Negative<Num> = NonPositive<Num> & NonZero<Num>;

export type NegativeModule<Num> = {
  /**
   * Checks if value is a valid Negative
   */
  readonly is: (a: Num) => a is Negative<Num>;
  /**
   * Parses a Num to a Negative or throws when invalid.
   */
  readonly from: (n: Num) => Result<Negative<Num>, Error>;
  /**
   * Parses a Num to a Negative or throws when invalid.
   */
  readonly unsafeFrom: (n: Num) => Negative<Num>;
  /**
   * Build new JSON codec for Negative<Num>
   */
  readonly json: <O, I>(codec: t.Type<Num, O, I>) => t.Type<Negative<Num>, O, I>;
};

const build = <Num>(num: ExtendedCalculator<Num>): NegativeModule<Num> => {
  const is = (a: Num): a is Negative<Num> => {
    return num.lessThan(a, num.zero());
  };

  const from = (n: Num): Result<Negative<Num>, Error> => {
    if (!is(n)) {
      return err(new Error('Invalid Negative<Num> value'));
    }

    return ok(n);
  };

  const unsafeFrom = (n: Num): Negative<Num> => {
    if (!is(n)) {
      throw new Error('Invalid Negative<Num> value');
    }

    return n;
  };

  const json = <O, I>(codec: t.Type<Num, O, I>): t.Type<Negative<Num>, O, I> => {
    return t.brand(codec, is, 'NonPositive');
  };

  return {
    is,
    from,
    unsafeFrom,
    json,
  };
};

export const Negative = {
  build,
};
