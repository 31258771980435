import { CheckoutTransactionId, CheckoutTransactionCode } from '../../checkout';
import { PageId } from '../../pages';
import { ProductMeasure } from '../products/product/productMeasure';
import { ProductBarcodeC, ProductIdC, ProfileImageC } from '../products/productsHttpClient/productsJson';
import { ProductSale, ProductSaleStatus } from './productSale';
import {
  ObjectId,
  JSONable,
  DefinedTrimedString,
  PortionPercentScaledNumber,
  Money,
  ScaledNumber,
  MeroUnits,
  HasOptionalFirstLastName,
} from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

const moneyC: t.Type<Money<ScaledNumber, MeroUnits.RON>, JSONable> = Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON);

const CheckoutTransactionIdC: t.Type<CheckoutTransactionId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is CheckoutTransactionId => true,
  'CheckoutTransactionId',
);

const CheckoutTransactionCodeC: t.Type<CheckoutTransactionCode, string> = t.brand(
  t.string,
  CheckoutTransactionCode.is,
  'CheckoutTransactionCode',
);

export const ProductSaleStatusC: t.Type<ProductSaleStatus, JSONable> = t.union(
  [t.literal('Deleted'), t.literal('Draft'), t.literal('Finished')],
  'ProductSaleStatusC',
);

const PageIdC: t.Type<PageId, string> = PageId;

export const ProductSaleC: t.Type<ProductSale, JSONable> = t.exact(
  t.intersection(
    [
      t.type(
        {
          productId: ProductIdC,
          transactionItemId: t.string,
          pageId: PageIdC,
          name: DefinedTrimedString,
          measure: ProductMeasure.JSON,
          quantity: ScaledNumber.Positive.JSON,
          vatRate: PortionPercentScaledNumber.JSON,
          transactionId: CheckoutTransactionIdC,
          code: CheckoutTransactionCodeC,
          createdAt: tt.DateFromISOString,
          status: ProductSaleStatusC,
          total: moneyC,
        },
        '!',
      ),
      t.partial(
        {
          mainImage: ProfileImageC,
          client: HasOptionalFirstLastName,
          barcode: ProductBarcodeC,
        },
        '?',
      ),
    ],
    'ProductSale',
  ),
  'Exact<ProductSale>',
);

export const ProductSaleArrayC: t.Type<ProductSale[], JSONable> = t.array(ProductSaleC);
