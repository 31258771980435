import { Result, ok, err } from '../common/result';
import { ExtendedCalculator } from './extendedCalculator';
import * as t from 'io-ts';

export interface NonPositiveBrand {
  readonly NonPositive: unique symbol;
}

export type NonPositive<Num> = t.Branded<Num, NonPositiveBrand>;

export type NonPositiveModule<Num> = {
  /**
   * Checks if value is a valid NonPositive<Num> value
   */
  readonly is: (a: Num) => a is NonPositive<Num>;
  /**
   * Parses a Num to a NonPositive or returns an error
   */
  readonly from: (n: Num) => Result<NonPositive<Num>, Error>;
  /**
   * Parses a Num to a NonPositive or throws when invalid.
   */
  readonly unsafeFrom: (n: Num) => NonPositive<Num>;
  /**
   * Build new JSON codec for Postive<Num>
   */
  readonly json: <O, I>(codec: t.Type<Num, O, I>) => t.Type<NonPositive<Num>, O, I>;
};

const build = <Num>(num: Pick<ExtendedCalculator<Num>, 'lessThanOrEqual' | 'zero'>): NonPositiveModule<Num> => {
  const is = (a: Num): a is NonPositive<Num> => {
    return num.lessThanOrEqual(a, num.zero());
  };

  const from = (n: Num): Result<NonPositive<Num>, Error> => {
    if (!is(n)) {
      return err(new Error('Invalid NonPositive<Num> value'));
    }

    return ok(n);
  };

  const unsafeFrom = (n: Num): NonPositive<Num> => {
    if (!is(n)) {
      throw new Error('Invalid NonPositive<Num> value');
    }

    return n;
  };

  const json = <O, I>(codec: t.Type<Num, O, I>): t.Type<NonPositive<Num>, O, I> => {
    return t.brand(codec, is, `NonPositive`);
  };

  return {
    is,
    from,
    unsafeFrom,
    json,
  };
};

export const NonPositive = {
  build,
};
