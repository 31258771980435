import { Result, ok, err } from '../common/result';
import { ExtendedCalculator } from './extendedCalculator';
import * as t from 'io-ts';

export interface NonZeroBrand {
  readonly NonZero: unique symbol;
}

export type NonZero<Num> = t.Branded<Num, NonZeroBrand>;

export type NonZeroModule<Num> = {
  /**
   * Checks if value is a valid NonZero
   */
  readonly is: (a: Num) => a is NonZero<Num>;
  /**
   * Parses a Num to a NonZero or returns an error.
   */
  readonly from: (n: Num) => Result<NonZero<Num>, Error>;
  /**
   * Parses a Num to a NonZero or throws when invalid.
   */
  readonly unsafeFrom: (n: Num) => NonZero<Num>;
  /**
   * Build new JSON codec for NonZero<Num>
   */
  readonly json: <O, I>(codec: t.Type<Num, O, I>) => t.Type<NonZero<Num>, O, I>;
};

const build = <Num>(num: Pick<ExtendedCalculator<Num>, 'equals' | 'zero'>): NonZeroModule<Num> => {
  const is = (a: Num): a is NonZero<Num> => {
    return !num.equals(a, num.zero());
  };

  const from = (n: Num): Result<NonZero<Num>, Error> => {
    if (!is(n)) {
      return err(new Error('Invalid NonZero<Num> value'));
    }

    return ok(n);
  };

  const unsafeFrom = (n: Num): NonZero<Num> => {
    if (!is(n)) {
      throw new Error('Invalid NonZero<Num> value');
    }

    return n;
  };

  const json = <O, I>(codec: t.Type<Num, O, I>): t.Type<NonZero<Num>, O, I> => {
    return t.brand(codec, is, `NonZero`);
  };

  return {
    is,
    from,
    unsafeFrom,
    json,
  };
};

export const NonZero = {
  build,
};
