import { JSONable } from './jsonable';
import * as t from 'io-ts';

export interface HourBrand {
  readonly Hour: unique symbol;
}

export type Hour = t.Branded<t.Int, HourBrand>;

const is = (hour: t.Int): hour is Hour => {
  return hour >= 0 && hour <= 23;
};

const JSON: t.Type<Hour, JSONable, unknown> = t.brand(t.Int, is, 'Hour');

export const Hour = {
  JSON,
};
